<template>
  <b-table-simple
    id="professional-participation-table"
    v-if="professionals?.length"
    responsive borderless fixed class="table"
    :style="!edit ?'width:98%' : ''"
  >
    <thead>
      <tr>
        <th v-if="guide == 'hospitalization_summary'"><Ellipsis>Seq. Ref</Ellipsis></th>
        <th><Ellipsis>Nome Profissional</Ellipsis></th>
        <th><Ellipsis>Grau part.</Ellipsis></th>
        <th><Ellipsis>Repasse Médico</Ellipsis></th>
        <th><Ellipsis>Código/CPF</Ellipsis></th>
        <th><Ellipsis>Conselho</Ellipsis></th>
        <th><Ellipsis>Nº Conselho</Ellipsis></th>
        <th>UF</th>
        <th>CBO</th>
        <th><Ellipsis>Red/Acresc</Ellipsis></th>
        <th><Ellipsis>Valor unitário</Ellipsis></th>
        <th><Ellipsis>Valor total</Ellipsis></th>
        <th v-if="edit" />
      </tr>
    </thead>
    <tbody>
      <tr v-for="(professional, index) of professionals" :key="index" class="tr-header">
        <td v-if="guide == 'hospitalization_summary'"><Ellipsis>{{professional.reference_sequence}}</Ellipsis></td>
        <td v-if="professionalOptions.find(option => option.value === professional.professional_id)">
          <Ellipsis>{{ professionalOptions.find(option => option.value === professional.professional_id)?.label }}</Ellipsis>
        </td>
        <td v-else>---</td>
        <td><Ellipsis>{{ grades.find(option => option.value === professional.degree_of_participation)?.label  || '---'}}</Ellipsis></td>
        <td><Ellipsis>{{ getOnlendingProfessionalName(professional) }}</Ellipsis></td>
        <td><Ellipsis>{{ professional.contractor_code || '---'}}</Ellipsis></td>
        <td><Ellipsis>{{ councils.find(option => option.value === professional.professional_council)?.label  || '---'}}</Ellipsis></td>
        <td><Ellipsis>{{ professional.professional_council_registration  || '---'}}</Ellipsis></td>
        <td>{{ professional.uf  || '---'}}</td>
        <td>{{ professional.cbo_code  || '---'}}</td>
        <td>{{ professional.decrease_or_increase_factor  || '---'}}</td>
        <td>{{ parseMoney(professional.unitary_value) }}</td>
        <td>{{ parseMoney(professional.value) }}</td>
        <td v-if="edit" class="text-right d-flex">
          <Edit
            v-if="(professional.onlending && professional.onlending_status === 'Em aberto') || !professional.onlending"
            v-b-tooltip.hover title="Editar"
            class="icon edit mr-1"
            @click="editProfessional(professional, index)"
          />

          <Delete
            v-if="(professional.onlending && professional.onlending_status === 'Em aberto') || !professional.onlending"
            v-b-tooltip.hover title="Remover"
            @click="$emit('deleteProfessional', { name: professionalOptions.find(option => option.value === professional.professional_id).label, index })"
            class="icon delete"
          />
        </td>
      </tr>
    </tbody>
  </b-table-simple>
</template>

<script>
export default {
  name: 'ProfessionalParticipationTableModule',
  components: {
    Delete: () => import('@/assets/icons/delete.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis')
  },
  props: {
    guide: String,
    edit: Boolean,
    professionals: Array,
    procedureValue: Number,
    councils: Array,
    grades: Array,
    professionalOptions: Array,
    invoicingTissSetting: Object,
    participationPercentage: Object,
    beneficiariesList: Array
  },
  methods: {
    parseMoney(value) {
      return parseFloat(value).toLocaleString('pt-br',{ style: 'currency', currency: 'BRL' });
    },
    editProfessional(professional, index) {
      this.$emit('edit-professional', { professional, index })
    },
    getOnlendingProfessionalName(professional){
      if(professional.onlending_professional?.name){
        return professional.onlending_professional.name;
      } else if(professional.onlending_professional_id){
        if(this.beneficiariesList?.length > 0){
          const beneficiary = this.beneficiariesList.find(benef => professional.onlending_professional_id === benef.id);
          if(beneficiary) return beneficiary.name;
        }
      } else {
        return '---';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/table.scss';
  .icon {
    width: 20px;
    height: 24px;
    cursor: pointer;
    &.edit {
      stroke: var(--blue-500);
    }
    &.delete {
      stroke: var(--red-500);
    }
  }
</style>
